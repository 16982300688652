/* eslint-disable prettier/prettier */
import React from "react";
import { NexcaliberLogo, NexcaliberWhiteLogo } from "../../../assets";
import { FOOTER_CONTACTS, FOOTER_LINKS, FOOTER_EMAILS, FOOTER_ADDRESS } from "../../../constants/footerDetails";
import { useLocation, Link } from "react-router-dom";

import { CiLocationOn } from "react-icons/ci";
import { AiOutlineMail } from "react-icons/ai";
import { FiPhoneCall } from "react-icons/fi";

import "./footerV2.css";

const FooterV2 = () => {
	// const buttonMailto = ({ mailto, label }) => {
	// 	return (
	// 		<Link
	// 			to="#"
	// 			onClick={(e) => {
	// 				window.location.href = mailto;
	// 				e.preventDefault();
	// 			}}
	// 		>
	// 			{label}
	// 		</Link>
	// 	);
	// };
	const route = useLocation().pathname;
	return (
		<footer className="footer">
			<div className="footer-content-part">
				<div className="nexcaliber-logo-container">
					<img className="nexcaliber-logo" src={NexcaliberWhiteLogo} alt="logo" />
					<div className="nexcaliber-info">SBPA Member Since 1988</div>
				</div>
				<div className="footer-row-link-container">
					<div className="footer-row-link-category">LINKS</div>
					<div className="footer-row-link-items-container">
						{FOOTER_LINKS.map((element, index) => {
							return (
								<div className="footer-row-link-items" key={index}>
									<div className="footer-link-label">
										{element.label === "Member Login" ? (
											<Link to={element.redirection_link} rel="noreferrer">
												{element.label}
											</Link>
										) : index === 0 || 1 ? (
											<a
												href={
													route === "/" && index === 0
														? "#section1"
														: route === "/" && index === 1
														? "#section2"
														: element.redirection_link
												}
											>
												{element.label}
											</a>
										) : (
											<Link to={element.redirection_link}>
												<a>{element.label}</a>
											</Link>
										)}
									</div>
								</div>
							);
						})}
					</div>
				</div>
				<div className="footer-row-contact-container">
					<div className="footer-row-contact-category">CALL US</div>
					<div className="footer-row-contact-items-container">
						{FOOTER_CONTACTS.map((contact, index) => {
							return (
								<div className="footer-row-contact-items" key={index}>
									<div className="footer-contact-label">{contact.contact_label}</div>
									<div className="footer-contact-value">{contact.contact_value}</div>
								</div>
							);
						})}
					</div>
				</div>
				<div className="footer-row-email-container">
					<div className="footer-row-email-category">EMAILS</div>
					<div className="footer-row-email-items-container">
						{FOOTER_EMAILS.map((email, index) => {
							return (
								<div className="footer-row-email-items" key={index}>
									<div className="footer-email-label">{email.label}</div>
									<div className="footer-email-value">
										<a href={`mailto:${email.value}`}>{email.value}</a>
									</div>
								</div>
							);
						})}
					</div>
				</div>

				<div className="footer-row-address-container">
					<div className="footer-row-address-category">ADDRESS</div>
					<div className="footer-row-address-items-container">
						{FOOTER_ADDRESS.map((address, index) => {
							return (
								<div className="footer-row-address-items" key={index}>
									<div className="footer-address-value">{address.value}</div>
								</div>
							);
						})}
					</div>
				</div>
			</div>
			<div className="footer-ribbon">
				<span>
					Nexcaliber © 2023 All Rights Reserved
					<span className="footer-ribbon-sbpa"> | SBPA Member Since 1988</span>
				</span>
			</div>
		</footer>
	);
};

export default FooterV2;
