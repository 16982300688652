// import React from "react";
import "./summaryOfProgressiveLaboratoriesPrint.css";
const SummaryOfProgressivePrint = () => {
	return (
		<div className="printable-summary-of-benefits-main-container">
			<div className="summary-of-benefits-header">Summary of Benefits for Medical Plan</div>
			<div className="printable-summary-of-benefits-body">
				<div className="printable-left-side">
					<div className="printable-body-container-top">
						<div className="printable-summary-of-benefits-sub-header">
							<div className="printable-sub-header-in-network">In Network</div>
							<div className="printable-sub-header-out-of-network">Out of Network</div>
						</div>
						<div className="printable-column-container">
							<div className="first-column">Deductible - Ind/Fam</div>
							<div className="second-column">$3,000/$6,000</div>
							<div className="third-column">$6,000/$12,000</div>
						</div>
						<div className="printable-column-container">
							<div className="first-column">
								Out-of-Pocket <br></br>- Ind/Fam
							</div>
							<div className="second-column">$4,000 / $8,000</div>
							<div className="third-column">$8,000/$16,000</div>
						</div>
						<div className="printable-column-container">
							<div className="first-column">Preventive care services</div>
							<div className="second-column">100%</div>
							<div className="third-column">100%</div>
						</div>
						<div className="printable-column-container">
							<div className="first-column">Office Visit</div>
							<div className="second-column">$20 Copay</div>
							<div className="third-column">50%</div>
						</div>
						<div className="printable-column-container">
							<div className="first-column">Annual routine physical examination</div>
							<div className="second-column">100%</div>
							<div className="third-column">100%</div>
						</div>
					</div>
				</div>
				<div className="printable-right-side">
					<div className="printable-body-container-bottom">
						<div className="printable-summary-of-benefits-sub-header">
							<div className="printable-sub-header-in-network">In Network</div>
							<div className="printable-sub-header-out-of-network">Out of Network</div>
						</div>
						<div className="printable-column-container">
							<div className="first-column">Hospital Services</div>
							<div className="second-column">90% after deductible</div>
							<div className="third-column">50% after deductible</div>
						</div>
						<div className="printable-column-container">
							<div className="first-column">Labrotory Sevice</div>
							<div className="second-column">90% after deductible</div>
							<div className="third-column">50% after deductible</div>
						</div>
						<div className="printable-column-container">
							<div className="first-column">Specialist consultation</div>
							<div className="second-column">$60 Copay</div>
							<div className="third-column">50% after deductible</div>
						</div>
						<div className="printable-column-container">
							<div className="first-column">Emergency room facility Copay waived if admitted</div>
							<div className="second-column">$75 Copay + 90%</div>
							<div className="third-column">$75 Copay + 90%</div>
						</div>
						<div className="printable-column-container">
							<div className="first-column">Outpatients Service Co-pay</div>
							<div className="second-column">90% per Incident</div>
							<div className="third-column">50% per Incident</div>
						</div>
						<div className="printable-column-container">
							<div className="first-column">Others Service</div>
							<div className="second-column">90%</div>
							<div className="third-column">50%</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default SummaryOfProgressivePrint;
